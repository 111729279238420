import React, { useCallback, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { DrillDownContentProps } from "./ClientFFRDrillDownContent"
import { fetchDrillDownClients, fetchDrillDownWarehouses, fetchDrillDownWarehousesNotDistributors } from "../../../redux/fsProducts/fsProductsSlice"
import { ActiveFsFilters } from "../../../redux/filters/filtersTypes"
import { CommonTabs, CustomTabPanel, DataTableDrillDown, messageToDisplay } from "./othersDrillDownComponents"
import { CellTooltip, getClientsColumns, getFSRows, getWarehousesColumns } from "./setupDrillDown"
import { DrillDownType } from "./ProductsDrillDownContent"
import Loading from "../screens/LoadingScreen"
import { GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid"
import { BLACK } from "../../ui/Colors"
import { PrimaryText } from "../../ui/Text"

export const ProductsEpiSaveursDrillDownContent = ({
    row,
    isFirstVisit,
    value,
    handleChange,
    type,
}: DrillDownContentProps) => {
    const [isLoadingClients, setLoadingClients] = useState(true)
    const [isLoadingWarehouses, setLoadingWarehouses] = useState(true)
    const [isLoadingWarehousesNotDistributors, setLoadingWarehousesNotDistributors] = useState(true)
    const dispatch = useAppDispatch()
    const { activeFsFilters } = useAppSelector((state) => state.filters)
    const { drillDownClients, drillDownWarehouses, drillDownWarehousesNotDistributors } = useAppSelector(
        (state) => state.fsProducts
    )

    const warehousesLength = drillDownWarehouses
        ? drillDownWarehouses.length
        : ''
    const warehousesNotDistributorsLength = drillDownWarehousesNotDistributors
        ? drillDownWarehousesNotDistributors.length
        : ''
    const clientsLength = drillDownClients ? drillDownClients.length : ''

    const _fetchDrillDownWarehouses = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchDrillDownWarehouses(filters))
        },
        [dispatch]
    )

    const _fetchDrillDownWarehousesNotDistributors = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchDrillDownWarehousesNotDistributors(filters))
        },
        [dispatch]
    )

    const _fetchDrillDownClients = useCallback(
        async (filters: ActiveFsFilters) => {
            await dispatch(fetchDrillDownClients(filters))
        },
        [dispatch]
    )

    const filters: ActiveFsFilters = {
        startDate: activeFsFilters.startDate,
        endDate: activeFsFilters.endDate,
        clients: activeFsFilters.clients,
        products: [row.id],
        regions: activeFsFilters.regions,
        categories: activeFsFilters.categories,
    }

    useEffect(() => {
        const fetchDataClients = async () => {
            await _fetchDrillDownClients(filters)
        }
        const fetchDataWarehouses = async () => {
            await _fetchDrillDownWarehouses(filters)
        }
        const fetchDataWarehousesNotDistributors = async () => {
            await _fetchDrillDownWarehousesNotDistributors(filters)
        }

        fetchDataWarehouses().then(() => setLoadingWarehouses(false))
        fetchDataWarehousesNotDistributors().then(() => setLoadingWarehousesNotDistributors(false))
        fetchDataClients().then(() => setLoadingClients(false))
    }, [])

    return (
        <CommonTabs
            handleChange={handleChange}
            menus={[
                'Informations',
                `Dépôts (${warehousesLength})`,
                `Dépôts non distributeurs (${warehousesNotDistributorsLength})`,
                `Utilisateurs finaux (${clientsLength})`,
            ]}
            row={row}
            value={value}
            type={type}
        >
            <CustomTabPanel value={value} index={1}>
                {drillDownWarehouses && !isLoadingWarehouses && (
                    <DataTableDrillDown
                        isFirstVisit={isFirstVisit}
                        rows={getFSRows(drillDownWarehouses)}
                        columns={getWarehousesColumns(type)}
                        message={messageToDisplay({
                            type: DrillDownType.WAREHOUSES,
                            value: row.row.warehouses,
                            oldValue: row.row.warehousesLastYear,
                        })}
                    />
                )}
                {isLoadingWarehouses && <Loading text={false} height="54vh" />}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                {drillDownWarehousesNotDistributors && !isLoadingWarehousesNotDistributors && (
                    <DataTableDrillDown
                        isFirstVisit={isFirstVisit}
                        rows={drillDownWarehousesNotDistributors}
                        columns={[
                            {
                                field: 'warehouse',
                                headerName: 'Dépôts',
                                editable: false,
                                flex: 1,
                                renderHeader: (params: GridColumnHeaderParams) => {
                                    return (
                                        <PrimaryText color={BLACK}>
                                            {params.colDef.headerName}
                                        </PrimaryText>
                                    )
                                },
                                renderCell: (params: GridRenderCellParams) => {
                                    return <CellTooltip text={params.value} />
                                },
                            },]}
                        message={`Psssst, ci-dessous la précieuse liste des dépôts non distributeurs de la référence ${row.row.productName}`}
                    />
                )}
                {isLoadingWarehousesNotDistributors && <Loading text={false} height="54vh" />}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                {drillDownClients && !isLoadingClients && (
                    <DataTableDrillDown
                        isFirstVisit={isFirstVisit}
                        rows={getFSRows(drillDownClients)}
                        columns={getClientsColumns(type)}
                        message={messageToDisplay({
                            type: DrillDownType.CLIENTS,
                            value: row.row.clientsSubSubType,
                            oldValue: row.row.clientsSubSubTypeLastYear,
                        })}
                    />
                )}
                {isLoadingClients && <Loading text={false} height="54vh" />}
            </CustomTabPanel>
        </CommonTabs>
    )
}