import { Typography, type TypographyProps } from '@mui/material'
import React from 'react'
import { DARK_BLUE, DARK_GREY } from './Colors'

interface TextProps extends Omit<TypographyProps, 'sx'> {
    bold?: boolean
    color?: string
    textDecoration?: string
    wrap?: string
}

export const Title = ({
    bold,
    color = DARK_BLUE,
    textDecoration = 'none',
    ...props
}: TextProps) => (
    <Typography
        sx={{
            color: color,
            fontWeight: bold ? 700 : 400,
            fontSize: { xs: '30px', md: '3vw' },
            textDecoration: textDecoration,
            fontFamily: ['Ubuntu', 'sans-serif'],
        }}
        {...props}
    >
        {props.children}
    </Typography>
)

export const SubTitle = ({
    bold,
    color = DARK_BLUE,
    textDecoration = 'none',
    ...props
}: TextProps) => (
    <Typography
        sx={{
            color: color,
            fontWeight: bold ? 700 : 400,
            fontSize: { xs: '3vmin', md: '2vmin' },
            fontFamily: ['Ubuntu', 'sans-serif'],

            textDecoration: textDecoration,
        }}
        {...props}
    >
        {props.children}
    </Typography>
)

export const PrimaryText = ({
    bold,
    color = DARK_GREY,
    textDecoration = 'none',
    ...props
}: TextProps) => (
    <Typography
        sx={{
            color: color,
            fontWeight: bold ? 700 : 400,
            fontSize: { xs: '16px', md: '1.7vmin' },
            fontFamily: ['Cabin', 'sans-serif'],
            textDecoration: textDecoration,
        }}
        {...props}
    >
        {props.children}
    </Typography>
)

export const SecondaryText = ({
    bold,
    color = DARK_GREY,
    textDecoration = 'none',
    wrap = "noWrap",
    ...props
}: TextProps) => (
    <Typography
        sx={{
            color: color,
            fontWeight: bold ? 700 : 400,
            fontSize: { xs: '16px', md: '1.4vmin' },
            fontFamily: ['Cabin', 'sans-serif'],
            textDecoration: textDecoration,
            wrap: wrap
        }}
        {...props}
    >
        {props.children}
    </Typography>
)

export const Annotation = ({
    bold,
    color = DARK_GREY,
    textDecoration = 'none',
    ...props
}: TextProps) => (
    <Typography
        sx={{
            color: color,
            fontWeight: bold ? 700 : 400,
            fontSize: { xs: '16px', md: '1.2vmin' },
            textDecoration: textDecoration,
        }}
        {...props}
    >
        {props.children}
    </Typography>
)
