import React, { useCallback, useEffect, useState } from 'react'
import {
    getProductsColumns,
    getProductsNotDistributedColumns,
    getRetailRows,
} from './setupDrillDown'
import {
    CommonTabs,
    CustomTabPanel,
    DataTableDrillDown,
    messageToDisplay,
} from './othersDrillDownComponents'
import { DrillDownType } from './ProductsDrillDownContent'
import { DrillDownContentProps } from './ClientFFRDrillDownContent'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { ActiveRetailFilters } from '../../../redux/filters/filtersTypes'
import { fetchDrillDownProducts } from '../../../redux/retailProducts/retailProductsSlice'
import Loading from '../screens/LoadingScreen'

export const StoresDrillDownContent = ({
    row,
    value,
    handleChange,
    isFirstVisit,
}: DrillDownContentProps) => {
    const [isLoading, setLoading] = useState(true)
    const dispatch = useAppDispatch()
    const { activeRetailFilters } = useAppSelector((state) => state.filters)
    const _fetchDrillDownProducts = useCallback(
        async (filters: ActiveRetailFilters) => {
            await dispatch(fetchDrillDownProducts(filters))
        },
        [dispatch]
    )

    const { drillDownProducts } = useAppSelector(
        (state) => state.retailProducts
    )
    const productsLength = drillDownProducts
        ? drillDownProducts.productsDistributed.length
        : ''

    const productsNotDistributedLength = drillDownProducts
        ? drillDownProducts.productsNotDistributed.length
        : ''

    const filters: ActiveRetailFilters = {
        startDate: activeRetailFilters.startDate,
        endDate: activeRetailFilters.endDate,
        products: activeRetailFilters.products,
        stores: [row.id],
        distributionChannels: activeRetailFilters.distributionChannels,
    }

    let productsNotDistributedRows = []

    productsNotDistributedRows =
        drillDownProducts &&
        drillDownProducts.productsNotDistributed.map((el) => {
            return {
                id: el.productName,
                productName: el.productName,
                VMM: el.VMM
            }
        })

    useEffect(() => {
        const fetchData = async () => {
            await _fetchDrillDownProducts(filters)
        }

        fetchData().then(() => setLoading(false))
    }, [])

    return (
        <CommonTabs
            handleChange={handleChange}
            menus={[
                'Informations',
                `Références (${productsLength})`,
                `Références non distribuées (${productsNotDistributedLength})`,
            ]}
            row={row}
            value={value}
            type={DrillDownType.STORES}
        >
            <CustomTabPanel value={value} index={1}>
                {drillDownProducts && !isLoading && (
                    <DataTableDrillDown
                        isFirstVisit={isFirstVisit}
                        rows={getRetailRows(
                            drillDownProducts.productsDistributed
                        )}
                        columns={getProductsColumns(DrillDownType.STORES)}
                        message={messageToDisplay({
                            type: DrillDownType.RETAILPRODUCTS,
                            value: row.row.references,
                            oldValue: row.row.oldReferences,
                        })}
                    />
                )}
                {isLoading && <Loading text={false} height="54vh" />}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                {drillDownProducts && !isLoading && (
                    <DataTableDrillDown
                        isFirstVisit={isFirstVisit}
                        rows={productsNotDistributedRows}
                        columns={getProductsNotDistributedColumns()}
                        message={`Psssst, ci-dessous la précieuse liste des références  que vous pourriez pousser dans le point de vente ${row.id}`}
                    />
                )}
                {isLoading && <Loading text={false} height="54vh" />}
            </CustomTabPanel>
        </CommonTabs>
    )
}
