import React, { useCallback } from 'react'
import {
    trashFsFilters,
    trashRetailFilters,
    setActiveFsFilters,
    setActiveFsViewFilters,
    setActiveRetailFilters,
    setActiveRetailViewFilters,
    setActiveFsCrossFilters,
    trashFsCrossFilters,
    setActiveFsCrossViewFilters,
} from '../../../redux/filters/filtersSlice'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import { FiltersType } from './Filters'
import FsFiltersForm, {
    FormValuesFSFiltersForm,
} from './foodService/FsFiltersForm'
import RetailFiltersForm, {
    FormValuesRetailFiltersForm,
} from './retail/RetailFiltersForm'
import {
    ActiveFsCrossFilters,
    ActiveFsFilters,
    ActiveRetailFilters,
} from '../../../redux/filters/filtersTypes'
import { isDashboardFiltered } from '../../../redux/global/utils'
import { setIsFiltered } from '../../../redux/global/globalSlice'
import { isViewFiltered } from './utils'
import { PagesName } from '../navigation/types/pages'
import FsCrossFiltersForm, {
    FormValuesFSCrossFiltersForm,
} from './foodService/FsCrossFiltersForm'

export const SwitchFiltersType = ({
    filtersType,
    filters,
    page,
}: {
    filtersType: FiltersType
    filters: ActiveFsFilters | ActiveRetailFilters
    page?: PagesName
}) => {
    const dispatch = useAppDispatch()
    switch (filtersType) {
        case FiltersType.FS:
            const { activeFsFilters, fsFilters } = useAppSelector(
                (state) => state.filters
            )
            const _trashFsFilters = useCallback(
                () => dispatch(trashFsFilters()),
                [dispatch]
            )

            const _setIsFilteredFS = useCallback(
                (isFiltered: boolean) => dispatch(setIsFiltered(isFiltered)),
                [dispatch]
            )

            const isFilteredFS = useAppSelector(
                (state) => state.global.isFiltered
            )

            const resetFiltersFS = () => {
                if (isFilteredFS) {
                    _trashFsFilters()
                    _setIsFilteredFS(false)
                }
            }
            const handleSubmitGeneral = (values: FormValuesFSFiltersForm) => {
                const selectedValues = {
                    startDate: activeFsFilters.startDate,
                    endDate: activeFsFilters.endDate,
                    products: values.products,
                    clients: values.clients,
                    regions: values.regions,
                    categories: values.categories,
                }
                const isFiltered = isDashboardFiltered(
                    fsFilters,
                    selectedValues
                )
                _setIsFilteredFS(isFiltered)

                dispatch(setActiveFsFilters(selectedValues))
            }
            return (
                <FsFiltersForm
                    handleSubmit={handleSubmitGeneral}
                    resetMethod={resetFiltersFS}
                    filters={filters as ActiveFsFilters}
                    page={page}
                />
            )
        case FiltersType.FSCROSS:
            const { activeFsCrossFilters, fsCrossFilters } = useAppSelector(
                (state) => state.filters
            )


            const _trashFsCrossFilters = useCallback(
                () => dispatch(trashFsCrossFilters()),
                [dispatch]
            )

            const _setIsFilteredFsCross = useCallback(
                (isFiltered: boolean) => dispatch(setIsFiltered(isFiltered)),
                [dispatch]
            )

            const isFilteredFsCross = useAppSelector(
                (state) => state.global.isFiltered
            )
            const resetFiltersFsCross = () => {
                if (isFilteredFsCross) {
                    _trashFsCrossFilters()
                    _setIsFilteredFsCross(false)
                }
            }
            const handleSubmitFsCross = (
                values: FormValuesFSCrossFiltersForm
            ) => {
                const selectedFsCrossValues = {
                    startDate: activeFsCrossFilters.startDate,
                    endDate: activeFsCrossFilters.endDate,
                    products: values.products,
                    clients: values.clients,
                    distributors: values.distributors,
                }
                const isFiltered = isDashboardFiltered(
                    fsCrossFilters,
                    selectedFsCrossValues
                )
                _setIsFilteredFsCross(isFiltered)
                dispatch(setActiveFsCrossFilters(selectedFsCrossValues))
            }
            return (
                <FsCrossFiltersForm
                    handleSubmit={handleSubmitFsCross}
                    resetMethod={resetFiltersFsCross}
                    filters={filters as ActiveFsCrossFilters}
                    page={page}
                />
            )
        case FiltersType.VIEWSFSCROSS:
            const { fsCrossViewFilters, activeFsCrossViewFilters } = useAppSelector(
                (state) => state.filters
            )

            const _resetFSCrossViewFilters = useCallback(
                (filters: ActiveFsCrossFilters) =>
                    dispatch(setActiveFsCrossViewFilters(filters)),
                [dispatch]
            )

            const _setIsFilteredFSCrossView = useCallback(
                (isFiltered: boolean) => dispatch(setIsFiltered(isFiltered)),
                [dispatch]
            )

            const isFilteredFSCROSSView = useAppSelector(
                (state) => state.global.isFiltered
            )

            const resetFiltersViewsFSCross = (values: FormValuesFSCrossFiltersForm) => {
                if (isFilteredFSCROSSView) {
                    _resetFSCrossViewFilters({
                        startDate: fsCrossViewFilters.startDate,
                        endDate: fsCrossViewFilters.endDate,
                        products: values.products,
                        clients: values.clients,
                        distributors: values.distributors,
                    })
                    _setIsFilteredFSCrossView(false)
                }
            }
            const handleSubmitViewsFsCROSS = (values: FormValuesFSCrossFiltersForm) => {
                const selectedViewFSCrossValues = {
                    startDate: activeFsCrossViewFilters.startDate,
                    endDate: activeFsCrossViewFilters.endDate,
                    products: values.products,
                    clients: values.clients,
                    distributors: values.distributors,
                }

                const isViewFSFiltered = isViewFiltered(
                    fsCrossViewFilters,
                    selectedViewFSCrossValues
                )
                _setIsFilteredFSCrossView(isViewFSFiltered)
                dispatch(setActiveFsCrossViewFilters(selectedViewFSCrossValues))
            }
            return (
                <FsCrossFiltersForm
                    handleSubmit={handleSubmitViewsFsCROSS}
                    filters={filters as ActiveFsCrossFilters}
                    resetMethod={resetFiltersViewsFSCross}
                    page={page}
                />
            )
        case FiltersType.VIEWSFS:
            const { fsViewFilters, activeFsViewFilters } = useAppSelector(
                (state) => state.filters
            )

            const _resetFSViewFilters = useCallback(
                (filters: ActiveFsFilters) =>
                    dispatch(setActiveFsViewFilters(filters)),
                [dispatch]
            )

            const _setIsFilteredFSView = useCallback(
                (isFiltered: boolean) => dispatch(setIsFiltered(isFiltered)),
                [dispatch]
            )

            const isFilteredFSView = useAppSelector(
                (state) => state.global.isFiltered
            )

            const resetFiltersViewsFS = (values: FormValuesFSFiltersForm) => {
                if (isFilteredFSView) {
                    _resetFSViewFilters({
                        startDate: fsViewFilters.startDate,
                        endDate: fsViewFilters.endDate,
                        products: values.products,
                        clients: values.clients,
                        regions: values.regions,
                        categories: values.categories,
                    })
                    _setIsFilteredFSView(false)
                }
            }
            const handleSubmitViewsFs = (values: FormValuesFSFiltersForm) => {
                const selectedViewFSValues = {
                    startDate: activeFsViewFilters.startDate,
                    endDate: activeFsViewFilters.endDate,
                    products: values.products,
                    clients: values.clients,
                    regions: values.regions,
                    categories: values.categories,
                }

                const isViewFSFiltered = isViewFiltered(
                    fsViewFilters,
                    selectedViewFSValues
                )
                _setIsFilteredFSView(isViewFSFiltered)
                dispatch(setActiveFsViewFilters(selectedViewFSValues))
            }
            return (
                <FsFiltersForm
                    handleSubmit={handleSubmitViewsFs}
                    filters={filters as ActiveFsFilters}
                    resetMethod={resetFiltersViewsFS}
                    page={page}
                />
            )
        case FiltersType.RETAIL:
            const { activeRetailFilters, retailFilters } = useAppSelector(
                (state) => state.filters
            )
            const _trashRetailFilters = useCallback(
                () => dispatch(trashRetailFilters()),
                [dispatch]
            )

            const _setIsFilteredRetail = useCallback(
                (isFiltered: boolean) => dispatch(setIsFiltered(isFiltered)),
                [dispatch]
            )

            const isFilteredRetail = useAppSelector(
                (state) => state.global.isFiltered
            )

            const resetFiltersRetail = () => {
                if (isFilteredRetail) {
                    _trashRetailFilters()
                    _setIsFilteredRetail(false)
                }
            }
            const handleSubmitRetail = (
                values: FormValuesRetailFiltersForm
            ) => {
                const selectedRetailValues = {
                    startDate: activeRetailFilters.startDate,
                    endDate: activeRetailFilters.endDate,
                    products: values.products,
                    stores: values.stores,
                    distributionChannels: values.distributionChannels,
                }

                const isFiltered = isDashboardFiltered(
                    retailFilters,
                    selectedRetailValues
                )
                _setIsFilteredRetail(isFiltered)
                dispatch(setActiveRetailFilters(selectedRetailValues))
            }
            return (
                <RetailFiltersForm
                    handleSubmit={handleSubmitRetail}
                    filters={filters as ActiveRetailFilters}
                    resetMethod={resetFiltersRetail}
                />
            )

        case FiltersType.VIEWSRETAIL:
            const { retailViewFilters, activeRetailViewFilters } =
                useAppSelector((state) => state.filters)
            const _resetRetailViewFilters = useCallback(
                (filters: ActiveRetailFilters) =>
                    dispatch(setActiveRetailViewFilters(filters)),
                [dispatch]
            )

            const _setIsFilteredRetailView = useCallback(
                (isFiltered: boolean) => dispatch(setIsFiltered(isFiltered)),
                [dispatch]
            )

            const isFilteredRetailView = useAppSelector(
                (state) => state.global.isFiltered
            )
            const resetFiltersViewsRetail = (
                values: FormValuesRetailFiltersForm
            ) => {
                if (isFilteredRetailView) {
                    _resetRetailViewFilters({
                        startDate: retailViewFilters.startDate,
                        endDate: retailViewFilters.endDate,
                        products: values.products,
                        stores: values.stores,
                        distributionChannels: values.distributionChannels,
                    })
                    _setIsFilteredRetailView(false)
                }
            }
            const handleSubmitViewRetail = (
                values: FormValuesRetailFiltersForm
            ) => {
                const selectedViewRetailValues = {
                    startDate: activeRetailViewFilters.startDate,
                    endDate: activeRetailViewFilters.endDate,
                    products: values.products,
                    stores: values.stores,
                    distributionChannels: values.distributionChannels,
                }

                const isViewRetailFiltered = isViewFiltered(
                    retailViewFilters,
                    selectedViewRetailValues
                )
                _setIsFilteredRetailView(isViewRetailFiltered)
                dispatch(setActiveRetailViewFilters(selectedViewRetailValues))
            }
            return (
                <RetailFiltersForm
                    handleSubmit={handleSubmitViewRetail}
                    filters={filters as ActiveRetailFilters}
                    resetMethod={resetFiltersViewsRetail}
                />
            )
    }
}
