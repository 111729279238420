import React, { useState, useCallback } from 'react'
import {
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Popover,
} from '@mui/material'
import { BLACK, GREY } from '../../ui/Colors'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import {
    type Env,
    EnvStatus,
    EnvType,
    UsersRole,
    EnvName,
    KaryonModules,
} from '../../../redux/user/userTypes'
import {
    resetDashboard,
    resetGlobalKPIs,
    setGlobalEnv,
} from '../../../redux/global/globalSlice'
import { useNavigate } from 'react-router-dom'
import { PrimaryText, SubTitle } from '../../ui/Text'
import { distributorImages } from '../../../helpers/distributorsList'
import { PrimaryButton, SecondaryButton } from '../../ui/Button'
import {
    resetFSFilters,
    resetRetailFilters,
    setFsFilters,
    setRetailFilters,
} from '../../../redux/filters/filtersSlice'
import donutIcon from '../../../assets/icon/donutIcon.svg'
import HomeIcon from '@mui/icons-material/Home'
import { resetViews } from '../../../redux/views/viewsSlice'
import { resetPromotions } from '../../../redux/fsProducts/fsProductsSlice'
import { GhostEnvironnements } from './GhostEnvironnements'
import SolarImport from '../../../assets/solar_import-linear.svg'

interface EnvSelectorProps {
    open: boolean
    anchorEl: Element | null
    onClose: (e: React.MouseEvent) => void
    env: Env[]
}

interface EnvList {
    active: EnvName[]
    notActive: EnvName[]
}

interface FilterEnvByTypeProps {
    env: Env[]
    title?: string
    type?: EnvType
    onClose: (e: React.MouseEvent) => void
}

const allAvailableFSEnv = [EnvName.PASSIONFROID, EnvName.FRANCE_FRAIS, EnvName.METRO, EnvName.TRANSGOURMET, EnvName.SODEXO, EnvName.EPISAVEURS, EnvName.CERCLEVERT]
const allAvailableRetailEnv = [EnvName.INTERMARCHE, EnvName.CARREFOUR]

const SeparatorEnvNavigation = () => {
    return (
        <hr
            style={{
                width: '100%',
                height: '0.1vh',
                border: 'none',
                background: GREY,
                margin: '1vh 0',
            }}
        />
    )
}

const FilterEnvByType = ({
    onClose,
    env,
    title,
    type,
}: FilterEnvByTypeProps) => {
    const navigate = useNavigate()
    const { modules } = useAppSelector((state) => state.user.user)
    const dispatch = useAppDispatch()
    const _setEnv = useCallback(
        (env: Env) => dispatch(setGlobalEnv(env)),
        [dispatch]
    )

    const hasCrossDistrib = modules.includes(KaryonModules.CROSS)

    const _resetFSFilters = useCallback(() => {
        dispatch(setFsFilters(null))
        dispatch(resetFSFilters())
        dispatch(resetDashboard())
    }, [dispatch])
    const _resetRetailFilters = useCallback(() => {
        dispatch(setRetailFilters(null))
        dispatch(resetRetailFilters())
        dispatch(resetDashboard())
    }, [dispatch])

    const handleEnvSelection = (e: React.MouseEvent, env: Env) => {
        if (env.type === EnvType.FOOD_SERVICE) {
            _resetFSFilters()
            _setEnv(env)
            onClose(e)
            navigate(`/food-service/${env.name}/dashboard`)
        } else if (env.type === EnvType.RETAIl) {
            _resetRetailFilters()
            _setEnv(env)
            onClose(e)
            navigate(`/retail/${env.name}/dashboard`)
        } else if (env.type === EnvType.FOOD_SERVICE_CROSS) {
            _setEnv(env)
            onClose(e)
            navigate(`/food-service/cross/dashboard`)
        } else if (env.type === EnvType.RETAIL_CROSS) {
            _setEnv(env)
            onClose(e)
            navigate(`/retail/cross/dashboard`)
        } else {
            _resetFSFilters()
            _resetRetailFilters()
            _setEnv(env)
            const navigateTo =
                title === 'Food Service'
                    ? '/parametres/import-fs'
                    : '/parametres/import-retail'
            navigate(navigateTo)
        }
    }

    const handleOptionsBtnClick = (
        e: React.MouseEvent,
        hasCrossDistrib: boolean
    ) => {
        if (hasCrossDistrib && type === EnvType.FOOD_SERVICE) {
            handleEnvSelection(e, {
                name:
                    EnvName.FOOD_SERVICE_CROSS,
                type:
                    EnvType.FOOD_SERVICE_CROSS,
                status: EnvStatus.ACTIVATED,
            })
        } else {
            handleEnvSelection(e, {
                name: EnvName.MYDISTRIB,
                type: EnvType.SETTING,
                status: EnvStatus.ACTIVATED,
            })
        }
    }

    return (
        <Grid>
            <Grid
                display={'flex'}
                justifyContent="space-between"
                alignItems={'center'}
            >
                <PrimaryText
                    style={{
                        color: BLACK,
                        padding: '1vh',
                        fontWeight: 'bold',
                        margin: '1vh',
                        width: "100%"
                    }}
                >
                    {title}
                </PrimaryText>
                {hasCrossDistrib && type === EnvType.FOOD_SERVICE ?
                    (<PrimaryButton
                        type="button"
                        style={{
                            padding: '1vh',
                            minWidth: '1vw',
                            margin: '1vh',
                            maxHeight: '1vh',
                        }}
                        onClick={(e) => {
                            handleOptionsBtnClick(e, hasCrossDistrib)
                        }}
                    >
                        <HomeIcon fontSize="small" />
                    </PrimaryButton>) : type === EnvType.RETAIl && (
                        <img
                            style={{
                                cursor: "pointer",
                                padding: '1vh',
                                minWidth: '1vw',
                                margin: '1vh',
                            }}
                            onClick={(e) => {
                                handleOptionsBtnClick(e, hasCrossDistrib)
                            }}
                            alt="Importer"
                            src={SolarImport}
                        />
                    )}
            </Grid>
            <List dense sx={{ padding: 0 }}>
                {env.map(
                    (listEnv) =>
                        listEnv.type === type && (
                            <ListItem
                                id={`${listEnv.name}-distributor`}
                                sx={{
                                    cursor: 'pointer',
                                    p: '1vh',
                                    margin: '1vh',
                                }}
                                onClick={(e) => {
                                    handleEnvSelection(e, listEnv)
                                }}
                                key={listEnv.name}
                            >
                                <ListItemIcon>
                                    <img
                                        style={{
                                            width: '3vw',
                                            paddingRight: '0.5vw',
                                        }}
                                        alt="Logo du distributeur"
                                        src={distributorImages[listEnv.name]}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={listEnv.name}
                                    sx={{
                                        '& .MuiTypography-root': {
                                            fontSize: '1.6vmin !important',
                                        },
                                    }}
                                />
                            </ListItem>
                        )
                )}
            </List>
        </Grid>
    )
}

const EnvSelector = ({ open, anchorEl, onClose, env }: EnvSelectorProps) => {
    const { role } = useAppSelector((state) => state.user.user)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const _setEnv = useCallback(
        (env: Env) => dispatch(setGlobalEnv(env)),
        [dispatch]
    )

    const _resetRetailFilters = useCallback(
        () => dispatch(resetRetailFilters()),
        [dispatch]
    )

    const _resetFsFilters = useCallback(
        () => dispatch(resetFSFilters()),
        [dispatch]
    )

    const _resetPromotions = useCallback(
        () => dispatch(resetPromotions()),
        [dispatch]
    )
    const _resetKPIs = useCallback(
        () => dispatch(resetGlobalKPIs()),
        [dispatch]
    )

    const _resetViews = useCallback(() => dispatch(resetViews()), [dispatch])

    const { fs } = useAppSelector((state) => state.user.user)
    const { retail } = useAppSelector((state) => state.user.user)

    // Sort environnement by alphabetic name in the menu
    env.sort((a, b) => {
        return a.name > b.name ? 1 : a.name === b.name ? 0 : -1
    })

    const handleEnvSelection = (e: React.MouseEvent, env: Env) => {
        _resetRetailFilters()
        _resetFsFilters()
        _resetViews()
        _resetPromotions()
        _resetKPIs()
        _setEnv(env)
        onClose(e)
    }

    //Create list of environnements : user environnements and available environnements
    const othersEnvironnementsRetail: EnvName[] = allAvailableRetailEnv.filter((el) => env.findIndex(env => env.name === el) < 0)
    const othersEnvironnementsFS: EnvName[] = allAvailableFSEnv.filter((el) => env.findIndex(env => env.name === el) < 0)

    return (
        <Popover
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
        >
            <List dense sx={{ padding: 0, minWidth: '15vw' }}>
                <Grid>
                    <FilterEnvByType
                        type={EnvType.RETAIl}
                        title="Retail"
                        env={env}
                        onClose={onClose}
                    />
                </Grid>
                {othersEnvironnementsRetail.length > 0 && <Grid>
                    <GhostEnvironnements env={othersEnvironnementsRetail} />
                </Grid>}
                <Grid>
                    <FilterEnvByType
                        type={EnvType.FOOD_SERVICE}
                        title="Food Service"
                        env={env}
                        onClose={onClose}
                    />
                </Grid>
                {othersEnvironnementsFS.length > 0 && <Grid>
                    <GhostEnvironnements env={othersEnvironnementsFS} />
                </Grid>}
                <Divider />
                {role === UsersRole.ADMIN && (
                    <ListItem
                        sx={{ cursor: 'pointer', p: '1vh 10px' }}
                        onClick={(e) => {
                            handleEnvSelection(e, {
                                name: EnvName.MYDISTRIB,
                                type: EnvType.SETTING,
                                status: EnvStatus.ACTIVATED,
                            })
                            navigate('/parametres/import-fs')
                        }}
                    >
                        <ListItemText
                            sx={{
                                '& .MuiTypography-root': {
                                    fontSize: '1.6vmin !important',
                                },
                            }}
                            primary="Importer des données"
                        />
                    </ListItem>
                )}
            </List>
        </Popover>
    )
}

const MultipleEnv = ({
    name,
    type,
    handler,
}: {
    name: string
    type: EnvType
    handler: (e) => void
}) => {
    return (
        <Grid
            id="env-selector"
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
            sx={{ cursor: 'pointer' }}
            onClick={(e) => {
                handler(e)
            }}
        >
            {[
                EnvType.SETTING,
                EnvType.FOOD_SERVICE_CROSS,
                EnvType.RETAIL_CROSS,
            ].includes(type) ? (
                <ListItemIcon
                    sx={{
                        justifyContent: 'center',
                        minWidth: '3vw',
                    }}
                >
                    <img src={donutIcon} width="40%" />
                </ListItemIcon>
            ) : (
                <ListItemIcon
                    sx={{
                        justifyContent: 'center',
                        minHeight: '2.5vh',
                        width: '2.5vw',
                        height: '1vh',
                    }}
                >
                    <img
                        alt="Logo du distributeur"
                        src={distributorImages[name]}
                    />
                </ListItemIcon>
            )}
            <PrimaryText
                color={BLACK}
                noWrap
                textOverflow="ellipsis"
                pl="0.5vw"
            >
                {name}
            </PrimaryText>
            <ListItemIcon
                sx={{
                    justifyContent: 'flex-end',
                    minWidth: '1vw',
                    '& svg': {
                        width: '100%',
                        height: '2.5vh',
                    },
                }}
            >
                <UnfoldMoreIcon />
            </ListItemIcon>
        </Grid>
    )
}

const UniqueEnv = ({ name, type }: { name: string; type: EnvType }) => {
    return (
        <Grid container mb="1vh" mt="1vh" justifyContent="space-between">
            <SubTitle color={BLACK}>{name}</SubTitle>
            {type !== EnvType.SETTING ? (
                <ListItemIcon>
                    <img
                        style={{ width: '3vw', paddingRight: '0.5vw' }}
                        alt="Logo du distributeur"
                        src={distributorImages[name]}
                    />
                </ListItemIcon>
            ) : (
                <img src={donutIcon} />
            )}
        </Grid>
    )
}

const EnvNavigation = () => {
    const [open, setOpen] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const { env } = useAppSelector((state) => state.user.user)
    const currentEnv = useAppSelector((state) => state.global.env)

    const handleClick = (e: React.MouseEvent) => {
        e.preventDefault()

        if (open) {
            setAnchorEl(null)
            setOpen(false)
        } else {
            setAnchorEl(e.currentTarget)
            setOpen(true)
        }
    }

    return (
        <Grid container direction="column" mb="4vh" justifyContent="center">
            <SeparatorEnvNavigation />
            {env.length > 1 ? (
                <MultipleEnv
                    type={currentEnv.type}
                    name={currentEnv.name}
                    handler={handleClick}
                />
            ) : (
                <UniqueEnv type={currentEnv.type} name={currentEnv.name} />
            )}
            <SeparatorEnvNavigation />
            <EnvSelector
                open={open}
                anchorEl={anchorEl}
                onClose={(e: React.MouseEvent) => {
                    handleClick(e)
                }}
                env={env.filter((env) => env.type !== EnvType.SETTING)}
            />
        </Grid>
    )
}

export default EnvNavigation
