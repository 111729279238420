import React, { useState, useCallback } from 'react'
import { Drawer, Grid, Typography } from '@mui/material'
import { SwitchPeriod } from './SwitchPeriod'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import {
    fetchNotifications,
    fetchUpdatedNotification,
} from '../../../../redux/notifications/notificationsSlice'
import { BLACK, DARK_BLUE, SECONDARY } from '../../../ui/Colors'
import DrawerHeader from '../../notification/DrawerHeader'
import {
    handleViewCreation,
    updateData,
} from '../../../../redux/views/viewsSlice'
import {
    ResponseViewsCreation,
    ViewCreationMode,
} from '../../../../redux/views/viewsTypes'
import { PrimaryText, SecondaryText, SubTitle } from '../../../ui/Text'
import { AltButtonSpecial, PrimaryButton } from '../../../ui/Button'
import { Notification } from '../../../../redux/notifications/notificationsTypes'


const PrivateHeader = ({
    title,
    attribute,
    period,
    onViewPage,
}: {
    title: string
    attribute: number
    period?: boolean
    hasOtherUnitAvailable?: boolean
    onViewPage?: boolean
}) => {
    /* FOR SWITCH UNIT
    const dispatch = useAppDispatch()
    const _setUnit = useCallback(
        (unit: units) => dispatch(setUnit(unit)),
        [dispatch]
    )
    const { unit } = useAppSelector((state) => state.global)

    const handleChangeUnitButton = () => {
        if (unit === Units.kg) {
            _setUnit(Units.uvc)
        }
        if (unit === Units.uvc) {
            _setUnit(Units.kg)
        }
    } */

    const navigate = useNavigate()
    const [hasDetails, setDetails] = useState<boolean>(false)
    const [open, setOpen] = useState(false)
    const { notifications } = useAppSelector((state) => state.notifications)
    const { activeFsFilters } = useAppSelector((state) => state.filters)

    const { status } = useAppSelector((state) => state.user.user)
    const { env } = useAppSelector((state) => state.global)
    const { user } = useAppSelector((state) => state.user)
    const dispatch = useAppDispatch()
    const _fetchNotifications = useCallback(async () => {
        await dispatch(fetchNotifications())
    }, [dispatch])
    const [tabValue, setValue] = useState(0)

    const notificationsArchived =
        notifications &&
        notifications.filter((el) => el.archive.isArchived === true)

    const notificationsNotArchived =
        notifications &&
        notifications.filter((el) => el.archive.isArchived === false)

    /*     useEffect(() => {
        _fetchNotifications()
    }, [])
 */
    const _updateData = useCallback(
        ({
            products,
            productName,
            notificationId,
        }: {
            products: string[]
            productName: string
            notificationId: string
        }) =>
            dispatch(
                updateData({
                    data: {
                        name: `Vue ${productName}`,
                        environment: env.type,
                        distributor: env.name,
                        filters: {
                            clients: [],
                            products,
                            startDate: activeFsFilters.startDate,
                            endDate: activeFsFilters.endDate,
                            regions: [],
                            categories: [],
                        },
                        creationMode: ViewCreationMode.NOTIFICATION,
                        notificationId: notificationId,
                    },
                })
            ),
        [dispatch]
    )

    const _handleCreation = useCallback(
        async () => await dispatch(handleViewCreation()),
        [dispatch]
    )

    const handleChange = () => {
        setValue((value) => (value === 0 ? 1 : 0))
    }

    const handleCreation = (
        productId: string,
        productName: string,
        notificationId: string
    ) => {
        _updateData({
            products: [productId],
            productName: productName,
            notificationId: notificationId,
        })
        _handleCreation().then((view: ResponseViewsCreation) => {
            navigate(
                `/${view.environment}/${view.distributor}/vues/${view.insertedId}`
            )
        })
    }

    const _archiveNotification = useCallback(
        (notificationId: string, notificationUpdate: Partial<Notification>) => {
            dispatch(
                fetchUpdatedNotification(notificationId, notificationUpdate)
            )
        },
        [dispatch]
    )

    const handleArchive = (
        notificationId: string,
        notificationUpdate: Partial<Notification>
    ) => {
        _archiveNotification(notificationId, notificationUpdate)
    }

    return (
        <Grid
            container
            wrap="nowrap"
            alignItems="center"
            justifyContent="space-between"
            position="sticky"
        >
            <Grid container alignItems="center">
                <Typography
                    sx={{ fontSize: '1.7vw', fontWeight: 500 }}
                    ml="1vw"
                >
                    {title}
                </Typography>
                {attribute ? (
                    <Typography
                        sx={{ fontSize: '1.7vw', fontWeight: 500 }}
                        ml={1}
                    >
                        ({attribute})
                    </Typography>
                ) : null}
                {period && <SwitchPeriod onViewPage={onViewPage} />}
            </Grid>
            {/*    {status != UsersStatus.STANDALONE &&
                env.type !== EnvType.RETAIl && (
                    <Grid
                        position="relative"
                        onClick={() => setOpen(true)}
                        sx={{ cursor: 'pointer', display: 'flex' }}
                    >
                        <AutoAwesomeIcon
                            fontSize="large"
                            sx={{
                                color: '#FFC700',
                                mr: 2,
                                position: 'relative',
                            }}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                padding: 0.2,
                                borderRadius: 1,
                                backgroundColor: SECONDARY,
                                ml: 2.3,
                                mt: 2.3,
                            }}
                        >
                            {/* <PrimaryText bold color={PRIMARY}>
                                {notificationsNotArchived &&
                                    notificationsNotArchived.length}
                            </PrimaryText> 
                        </Box>
                    </Grid>
                    */}

            <Grid margin="1vh" mr="1.2vw" >


                <AltButtonSpecial href='https://8639535.fs1.hubspotusercontent-na1.net/hubfs/8639535/Guides et e-books/KaryonFood - Kit  Négociations Commerciales.zip'>Engagez des négociations commerciales équitables ! Téléchargez le kit imaginé par KaryonFood </AltButtonSpecial>

            </Grid>

            <Drawer
                anchor="right"
                PaperProps={{ sx: { width: '44vw' } }}
                open={open}
                onClose={() => setOpen(false)}
            >
                <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    sx={{ height: '100%' }}
                >
                    <DrawerHeader
                        tabValue={tabValue}
                        notificationsArchived={notificationsArchived}
                        notificationsNotArchived={notificationsNotArchived}
                        handleChange={handleChange}
                        setOpen={setOpen}
                    />
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        p="2vw"
                    >
                        <Grid item>
                            <SubTitle color={BLACK} mb="5vh" textAlign="center">
                                Bientôt disponible sur votre plateforme !
                            </SubTitle>
                            <PrimaryText
                                color={DARK_BLUE}
                                bold
                                style={{ background: SECONDARY }}
                                width="fit-content"
                            >
                                Détectez les risques de déréférencement
                            </PrimaryText>
                            <PrimaryText color={BLACK} mb="3vh">
                                Sur la base de vos données, nous identifions les
                                baisses significatives de vos ventes, celles qui
                                sont inhabituelles, et celles qui sont dues à la
                                perte d'un segment client. Nous vous alertons
                                des risques qui pourraient être causés par un
                                déréférencement, afin que vous puissiez réagir
                                rapidement.
                            </PrimaryText>
                            <PrimaryText
                                color={DARK_BLUE}
                                bold
                                style={{ background: SECONDARY }}
                                width="fit-content"
                            >
                                Identifiez les opportunités d'assortiment
                            </PrimaryText>
                            <PrimaryText color={BLACK} mb="3vh">
                                Si dans un dépôt une référence se vend auprès
                                d'une typologie d'utilisateur final spécifique,
                                nous ne manquerons pas de vous informer que
                                cette même clientèle achète également dans un
                                autre dépôt !
                            </PrimaryText>
                            <PrimaryText
                                color={DARK_BLUE}
                                bold
                                style={{ background: SECONDARY }}
                                width="fit-content"
                            >
                                Rejoignez notre programme de co-construction
                            </PrimaryText>
                            <PrimaryText color={BLACK} mb="5vh">
                                Si vous souhaitez partager avec nous vos cas
                                d'utilisation, nous avons lancé un programme de
                                co-construction qui vous permettra d'accéder en
                                exclusivité à cette fonctionnalité et de
                                participer à son développement.
                                <br />
                                L'inscription et le programme dans son
                                intégralité sont gratuits et mis en place pour
                                répondre à vos besoins !
                            </PrimaryText>
                        </Grid>
                        <Grid container direction="column" alignItems="center">
                            <PrimaryButton
                                style={{ width: '10vw' }}
                                onClick={() => setDetails(true)}
                            >
                                En savoir plus
                            </PrimaryButton>
                            {hasDetails && (
                                <SecondaryText color={BLACK} mt="2vh">
                                    Merci de votre intérêt, nous allons vous
                                    contacter !
                                </SecondaryText>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {/*     <DrawerContent
                    tabValue={tabValue}
                    notificationsArchived={notificationsArchived}
                    notificationsNotArchived={notificationsNotArchived}
                    handleArchive={handleArchive}
                    handleChange={handleChange}
                    handleViewCreation={handleCreation}
                    archiverId={user._id}
                /> */}
            </Drawer>
        </Grid>
    )
}

export default PrivateHeader
