import React from 'react'
import { Button, type ButtonProps } from '@mui/material'
import {
    DARK_BLUE,
    DARK_GREY,
    GREY,
    LIGHT_GREY,
    PRIMARY,
    WHITE,
} from './Colors'
import { Link, type LinkProps } from 'react-router-dom'

const buttonTextStyle = {
    fontWeight: 700,
    fontSize: { xs: '16px', md: '1.5vmin' },
    color: WHITE,
}

export const PrimaryButton = (props: ButtonProps) => {
    return (
        <Button
            sx={{
                ...buttonTextStyle,
                background: props.disabled ? LIGHT_GREY : PRIMARY,
                '&:hover': { background: DARK_BLUE, color: LIGHT_GREY },
            }}
            {...props}
        >
            {props.children}
        </Button>
    )
}

export const SecondaryButton = (props: ButtonProps) => (
    <Button
        sx={{
            ...buttonTextStyle,
            background: GREY,
            '&:hover': { background: DARK_GREY },
        }}
        {...props}
    >
        {props.children}
    </Button>
)

export const AltButton = (props: ButtonProps) => (
    <Button
        sx={{
            ...buttonTextStyle,
            color: PRIMARY,
            background: WHITE,
            border: `1px solid ${PRIMARY}`,
            '&:hover': { border: `1px solid ${DARK_BLUE}` },
        }}
        {...props}
    >
        {props.children}
    </Button>
)

export const AltButtonSpecial = (props: ButtonProps) => (
    <Button
        sx={{
            color: PRIMARY,
            background: WHITE,
            border: `1px solid ${PRIMARY}`,
            '&:hover': { border: `1px solid ` },
            textTransform: "none",
            textAlign: "center",
            padding: '1vh 1vw',
            width: "22vw",
            borderRadius: "2vw",
            fontSize: { xs: '16px', md: '1.5vmin' },
        }}
        {...props}
    >
        {props.children}
    </Button>
)

export const LinkButton = (props: LinkProps & ButtonProps) => (
    <Link to={props.to}>
        <Button
            sx={{ fontSize: { xs: '14px', md: '1.3vmin' }, color: DARK_GREY }}
            {...props}
        >
            {props.children}
        </Button>
    </Link>
)
